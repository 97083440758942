<template>
  <validation-observer ref="simpleRules">
    <b-overlay
      :show="OverLay"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-card>
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label for="username">ชื่อผู้ใช้ <small class="text-danger">*(ใส่ตัวอักษร หรือตัวเลขอย่างน้อย 2
                  ตัวอักษร)</small></label>
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      {{ UserData.agent_username }}
                    </b-input-group-prepend>
                    <b-form-input v-model="prename" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] ? 'กรุณากรอกชื่อผู้ใช้ให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="username">รหัสผ่าน<small class="text-danger">*</small></label>
                <b-form-input
                  v-model="PasswordValue"
                  type="password"
                />
              </b-form-group>
            </b-col>
            <b-col

              md="3"
            >
              <b-form-group v-if="PasswordValue">
                <label for="username">ยืนยันรหัสผ่าน<small class="text-danger">*</small></label>
                <b-form-input
                  v-model="passwordCon"
                  type="password"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group>
                <label for="username">ชื่อ<small class="text-danger">*</small></label>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] ? 'กรุณากรอกชื่อให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="username">นามสกุล<small class="text-danger">*</small></label>
                <validation-provider
                  #default="{ errors }"
                  name="lastname"
                  rules="required"
                >
                  <b-form-input
                    v-model="lastname"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] ? 'กรุณากรอกนามสกุลให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="username">เบอร์โทรศัพท์</label>
                <b-form-input
                  v-model="tel"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label for="username">Line ID</label>
                <b-form-input
                  v-model="line_id"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col md="3">
              <b-form-group>
                <label for="username">คำนำหน้า<small class="text-danger">*</small></label>
                <validation-provider
                  #default="{ errors }"
                  name="prefix"
                  rules="required"
                >
                  <b-form-input
                    v-model="prefix"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] ? 'กรุณากรอกนามสกุลให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col md="3">
              <b-form-group>
                <label for="username">ชื่อเว็บไซต์</label>
                <b-form-input
                  v-model="site"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="UserData.role === 'agseamless'"
              md="3"
            >
              <b-form-group>
                <label for="username">Call back domain<small class="text-danger">*</small></label>
                <validation-provider
                  #default="{ errors }"
                  name="lotto_urlcallback"
                  rules="required"
                >
                  <b-form-input
                    v-model="lotto_urlcallback"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] ? 'กรุณากรอกนามสกุลให้ถูกต้อง' : '' }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col md="3">
              <b-form-group>
                <label for="username">สกุลเงิน<small class="text-danger">*</small></label>
                <b-form-select
                  v-model="currency"
                  :options="currencyOp"
                />
              </b-form-group>
            </b-col> -->
          </b-row>
        </b-form>
        <hr>

        <div class="mt-1">
          <b-button
            variant="primary"
            type="submit"
            :disabled="Overlay"
            @click.prevent="validationForm"
          >
            บันทึกข้อมูล
          </b-button>
        </div>
      </b-card>
    </b-overlay>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
  // BFormCheckbox, BFormSelect,
  BInputGroupPrepend, BInputGroup,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import { required } from '@validations'
// import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    // BFormCheckbox,
    // BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    // vSelect,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      currentTab: 0,
      tabs: ['เปิด/ปิดกลุ่มหวย', 'แบ่งหุ้น/เก็บของ', 'ข้อมูลการแทง', 'อัตราจ่าย'],
      status: false,
      setShare: false,
      username: null,
      prename: null,
      name: null,
      lastname: null,
      tel: null,
      line_id: null,
      PasswordValue: null,
      passwordCon: null,
      prefix: null,
      site: null,
      lotto_urlcallback: null,
      copy_by: '',
      share_agent: 80,
      excess_percent: 0,
      huay_status: 0,
      currency: 'THB',
      userOp: [
        { title: 'Admin', value: 'admin' },
        { title: 'User', value: 'user' },
      ],
      excessOp: [
        { value: 0, text: '0.00%' },
        { value: 80, text: '80%' },
        { value: 90, text: '90%' },
        { value: 100, text: '100%' },
      ],
      currencyOp: [
        { value: 'THB', text: 'THB' },
        { value: 'USD', text: 'USD' },
      ],
      excess: 0,
      required,
      OverLay: false,
      ID: this.$route.params.id,
      OldData: {},
    }
  },
  mounted() {
    this.GetAg()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          if (this.PasswordValue) {
            if (this.PasswordValue !== this.passwordCon) {
              this.$bvToast.toast('รหัสผ่านไม่ตรงกัน', {
                title: 'แจ้งเตือน',
                variant: 'danger',
                solid: true,
              })
              return
            }
          }
          this.OverLay = true
          try {
            this.username = this.UserData.agent_username + this.prename
            const Obj = {
              id: this.ID,
              username: this.username,
              password: this.PasswordValue,
              name: this.name,
              lastname: this.lastname,
              tel: this.tel,
              line_id: this.line_id,
              // prefix: this.prefix,
              site: this.site,
              lotto_urlcallback: this.lotto_urlcallback,
            }
            await this.$http.post('agent/updateByUpline', Obj)
            this.Success('แก้ไขข้อมูลเอเยนต์สำเร็จ')
            this.OverLay = false
            this.$router.push({ name: 'member-list' })
          } catch (e) {
            this.Error('เกิดข้อผิดพลาด')
            this.OverLay = false
          }
        }
      })
    },
    async GetAg() {
      try {
        const { data: res } = await this.$http.get(`agent/adminshow/${this.ID}`)
        // this.OldData = { ...res }
        // console.log(this.OldData)
        this.prename = res.username
        this.prename = res.username.substring(this.UserData.agent_username.length)
        this.name = res.name
        this.lastname = res.lastname
        this.tel = res.tel
        this.line_id = res.line_id
        this.prefix = res.prefix
        this.site = res.site
        this.lotto_urlcallback = res.lotto_urlcallback
      } catch (e) {
        console.log(e)
      }
    },
    Success(title = '', mes = '') {
      this.$swal({
        icon: 'success',
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Error(title = '', mes = '') {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${title}!</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.active {
  background-color: #0068d0 !important;
  color: #fff !important;
}

.input-group-text {
  background-color: #0068d0 !important;
  color: #fff !important;
}
</style>
